import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import CampaignScreen from "./screens/CampaignScreen";
import ProofScreen from "./screens/ProofScreen";
import ResultScreen from "./screens/ResultScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MainScreen() {
  const [currentScreen, setCurrentScreen] = useState("campaign");
  const [isProofModalOpen, setIsProofModalOpen] = useState(false);
  const [campaignProgress, setCampaignProgress] = useState({
    netflix: false,
    prime: false,
    swiggy: false,
  });
  const [isEligible, setIsEligible] = useState(null);
  const [urlParams, setUrlParams] = useState({});

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16724377107";
    document.body.appendChild(script1);

    const comment = document.createComment(" Google tag (gtag.js) ");

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16724377107');
    `;

    document.head.appendChild(comment);
    document.head.appendChild(script1);
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(comment);
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []); 

  useEffect(() => {

    // Check if userId exists in localStorage, if not, generate and store one
   
    const contactInfo = localStorage.getItem("contactInfo");
    if (contactInfo) {
       // check if the isSwiggy and isAmazon are true
      if(localStorage.getItem("isSwiggy") === "false" && localStorage.getItem("isAmazon") === "true") {
        setIsEligible(true);
      } else {
        setIsEligible(false);
      }
      setCurrentScreen("result");
    }

    // Check URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    
    //get referralCodeUsed
    const referral = urlParams.get("referralCodeUsed");
    if (referral) {
      localStorage.setItem("referralCodeUsed", referral);
      console.log(`Referral ID captured: ${referral}`);
    }

    // render only if the url contains isSwiggy and isAmazon 
    if(urlParams.has('isSwiggy') && urlParams.has('isAmazon') && urlParams.has('isZomato')) {
    const isSwiggy = urlParams.get('isSwiggy')
    const isAmazon = urlParams.get('isAmazon')
    const isZomato = urlParams.get('isZomato')
    const uuid = urlParams.get('uuid')

    setUrlParams({ isSwiggy, isAmazon, uuid,  isZomato});
    if (isSwiggy !== null && isAmazon !== null && isZomato !== null) {
      if (isSwiggy === "false" && isAmazon === "true" && isZomato === "true") {
        setIsEligible(true);
      } else {
        setIsEligible(false);
      }
      localStorage.setItem("uuid", uuid)
      localStorage.setItem("isSwiggy", isSwiggy)
      localStorage.setItem("isAmazon", isAmazon)
      localStorage.setItem("isZomato", isZomato)
      window.history.replaceState({}, '', window.location.href.split('?')[0]);
      setCurrentScreen("result");
    }
  }
  }, []);

  const handleGoogleLogin = () => {

    if(window.location.origin === "https://proofofliving.in")
      window.location.href = "https://backend.proofofliving.in/api/google/auth";

    // swiggyonegiveaway -> /api/google/auth1
    else if(window.location.origin === "https://swiggyonegiveaway.in")
      window.location.href = "https://backend.proofofliving.in/api/google/auth1";

    //https://giveawayseason.in -> /api/google/auth2
    else 
      window.location.href = "https://backend.proofofliving.in/api/google/auth2";
      
  };

  //Check URL -> show PrivacyComp
  // if (window.location.pathname === "/privacy" || window.location.pathname === "/privacy/") {
  //   return (<PrivacyPolicyScreen />);
  // }

  return (
    <div className="h-screen w-screen">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {currentScreen === "campaign" && (
        <CampaignScreen onGoogleLogin={handleGoogleLogin} />
      )}
      {currentScreen === "result" && (
        <ResultScreen 
          isEligible={isEligible} 
        />
      )}
    </div>
  );
}

export default MainScreen;
