import React, { useState, useEffect } from "react";
import {
  FaEnvelope,
  FaPhone,
  FaUser,
  FaCheck,
  FaTimes,
  FaWhatsapp, 
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from 'uuid';

function ResultScreen({ isEligible }) {
  const [contactType, setContactType] = useState("phone");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [uid, setUid] = useState('');

  const updateAPI = async (source) => {

    if(source === "share") //share on whatsapp clicked -> dont update referralCodeUsed, update only userReferralCode
    {
      try {
        const response = await fetch(
          "https://backend.proofofliving.in/api/user/update",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user: localStorage.getItem("uuid"),
              name,
              phone,
              userReferralCode: localStorage.getItem("userReferralCode"),
              campaignSource: window.location.origin
            }),
          }
        );
        console.log(response);
      } catch (error) {
        console.error("Error updating API:", error);
      }

      return ;
    }

    //else -> coming from submitContactInfo -> update both
    try {
      const response = await fetch(
        "https://backend.proofofliving.in/api/user/update",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: localStorage.getItem("uuid"),
            name,
            phone,
            userReferralCode: localStorage.getItem("userReferralCode"),
            referralCodeUsed: localStorage.getItem("referralCodeUsed"),
            campaignSource: window.location.origin
          }),
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Error updating API:", error);
    }
  };

  useEffect(() => {
    const savedContact = localStorage.getItem("contactInfo");
    if (savedContact) {
      const { type, value } = JSON.parse(savedContact);
      setContactType(type);
      if (type === "email") {
        setEmail(value);
      } else {
        setPhone(value);
      }
      setIsSubmitted(true);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!phone?.trim().length) {
      toast.error("Please enter your phone number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    const contactInfo = contactType === "email" ? email : phone;
    localStorage.setItem(
      "contactInfo",
      JSON.stringify({ type: contactType, value: contactInfo })
    );
    updateAPI();
    setIsSubmitted(true);
    toast.success(
      "Thanks for submitting your details. We'll contact you soon with more information!",
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  };

  const handleShare = async () => {

    let userReferralCode = localStorage.getItem('userReferralCode');
  
    if (!userReferralCode) {
      const newUid = uuidv4();
      setUid(newUid);
      userReferralCode = newUid;
      localStorage.setItem('userReferralCode', userReferralCode);
    }

    await updateAPI("share");

    const url = window.location.origin;
    // const message = `🌟 Don't Miss Out! 🌟 You could win a Swiggy ONE Membership! Just prove you have an Amazon Prime membership and you don’t have a Swiggy ONE subscription. Log in with Google at https://proofofliving.in?referralCodeUsed=${userReferralCode}. Hurry! 🍽️🎊`;
    const message = `🌟 Don't Miss Out! 🌟 You could win a Swiggy ONE Membership! Just prove you have an Amazon Prime and Zomato Gold and you don’t have a Swiggy ONE subscription. Log in with Google at ${url}?referralCodeUsed=${userReferralCode}. Hurry! 🍽️🎊`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    
    window.open(whatsappUrl, '_blank'); // Open WhatsApp share link in a new tab
  };

  return (
    <div className="w-full h-full mx-auto flex items-center justify-center">
        <div className="fixed inset-0 overflow-hidden pointer-events-none">
          {isEligible ? (
            <div className="absolute inset-0 animate-crazy">
              {[...Array(50)].map((_, i) => (
                <div
                  key={i}
                  className="absolute rounded-full bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 opacity-0 animate-crazy-shape"
                  style={{
                    top: `${(i % 10) * 10}%`,
                    left: `${Math.floor(i / 10) * 20}%`,
                    width: "50px",
                    height: "50px",
                    animationDelay: `${i * 0.1}s`,
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="absolute inset-0">
              {[...Array(100)].map((_, i) => (
                <div
                  key={i}
                  className="absolute rounded-full bg-white opacity-0 animate-twinkle"
                  style={{
                    top: `${(i % 10) * 10}%`,
                    left: `${Math.floor(i / 10) * 10}%`,
                    width: "2px",
                    height: "2px",
                    animationDelay: `${i * 0.1}s`,
                  }}
                />
              ))}
              {[...Array(5)].map((_, i) => (
                <div
                  key={`meteor-${i}`}
                  className="absolute bg-white opacity-0 animate-meteor"
                  style={{
                    top: `${i * 20}%`,
                    left: "100%",
                    width: "2px",
                    height: "2px",
                    animationDelay: `${i * 2}s`,
                  }}
                />
              ))}
            </div>
          )}
        </div>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
          className="bg-white rounded-3xl p-8 shadow-2xl space-y-8 w-full max-w-md relative z-10"
        >
          <motion.div
            className="flex justify-center"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.2,
            }}
          >
            {isEligible ? (
              <div className="w-24 h-24 bg-gradient-to-r from-orange-400 to-orange-600 rounded-full flex items-center justify-center animate-pulse">
                <FaCheck className="text-white text-5xl" />
              </div>
            ) : (
              <div className="w-24 h-24 bg-gradient-to-r from-orange-400 to-orange-600 rounded-full flex items-center justify-center">
                <FaTimes className="text-white text-5xl" />
              </div>
            )}
          </motion.div>
          <motion.h2
            className="text-3xl font-bold text-center text-black"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {/* {isEligible
              ? "Congratulations! You're eligible!"
              : "We're sorry, you're not eligible."} */}

              {isEligible
              ? "Thank you for verifying your account! Please fill in your contact details, and we will contact you if you meet the eligibility criteria"
              : "Thank you for verifying your account! Please fill in your contact details, and we will contact you if you meet the eligibility criteria"}
              
          </motion.h2>
          <motion.p
            className="text-center text-gray-900"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            {/* {isEligible
              ? "You've successfully completed all tasks!"
              : "Don't worry, there will be more opportunities in the future."
              } */}
              {isEligible
              ? "You've successfully completed all tasks!"
              : "You've successfully completed all tasks!"
              }
          </motion.p>
          <motion.div
            className="space-y-6"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            {isSubmitted ? (
              <div className="text-center text-black">
                <p className="mb-4">Your {contactType}:</p>
                <p className="font-bold">
                  {contactType === "email" ? email : phone}
                </p>
                <p className="mt-4">
                  We'll contact you soon with more information!
                </p>
              
                <br />
                <button
                  onClick={handleShare}
                  className="w-full flex items-center justify-center bg-green-500 text-white font-bold py-4 px-6 rounded-full transition duration-300 ease-in-out hover:bg-green-600"
                >
                  <FaWhatsapp className="mr-2" /> Share on WhatsApp and win ₹
                </button>


              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="flex items-center justify-between">
                  <span className="text-white">Contact via:</span>
                </div>
                <div className="space-y-4">
                  <div className="relative">
                    <input 
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter your name"
                      required
                      className="w-full px-4 py-3 rounded-full bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 border-2 border-orange-500 pl-12"
                    />
                    <FaUser className="absolute left-4 top-1/2 transform -translate-y-1/2 text-orange-500" />
                  </div>
                  <div className="relative">
                    {contactType === "email" ? (
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email address"
                        required
                        className="w-full px-4 py-3 rounded-full bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 border-2 border-orange-500 pl-12"
                      />
                    ) : (
                      <input
                        type="tel"
                        value={phone}
                        maxLength={10}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Enter your phone number"
                        required
                        className="w-full px-4 py-3 rounded-full bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 border-2 border-orange-500 pl-12"
                      />
                    )}
                    {contactType === "email" ? (
                      <FaEnvelope className="absolute left-4 top-1/2 transform -translate-y-1/2 text-orange-500" />
                    ) : (
                      <FaPhone className="absolute left-4 top-1/2 transform -translate-y-1/2 text-orange-500" />
                    )}
                  </div>
                </div>
                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-gradient-to-r from-orange-400 to-orange-600 text-white font-bold py-4 px-6 rounded-full transition duration-300 ease-in-out transform hover:shadow-lg"
                >
                  {isEligible ? "Claim Your Reward" : "Stay Updated"}
                </motion.button>
                
                <button
                  onClick={handleShare}
                  className="w-full flex items-center justify-center bg-green-500 text-white font-bold py-4 px-6 rounded-full transition duration-300 ease-in-out hover:bg-green-600"
                >
                  <FaWhatsapp className="mr-2" /> Share on WhatsApp and win ₹
                </button>

                {/* <button
                  onClick={() => (window.location.href = "/proof")}
                  className="w-full flex items-center justify-center bg-gradient-to-r from-blue-400 to-blue-600 text-white font-bold py-4 px-6 rounded-full transition duration-300 ease-in-out hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-700"
                >
                Click here to earn more ₹
                </button> */}



              </form>
            )}
          </motion.div>
        </motion.div>
     
      <ToastContainer />

      
    </div>
  );
}

export default ResultScreen;