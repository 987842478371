import React from "react";

function PrivacyPolicyScreen() {
  const TitleTextMap = new Map([
    ["https://proofofliving.in", "Proof of Living"],
    ["https://swiggyonegiveaway.in", "Swiggy One Giveaway"],
    ["https://giveawayseason.in", "Giveaway Season"]
  ]);
  const titleText = TitleTextMap.get(window.location.origin) || "Proof of Living";
  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center font-roboto-condensed overflow-hidden p-4">
    <h1 className="text-white text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-bold mb-4 md:mb-8 text-center">
    {titleText}
    </h1>
    <div className="flex flex-col h-auto items-center justify-center text-center bg-white w-full max-w-7xl rounded-lg shadow-lg p-4 sm:p-6 md:p-8 lg:p-10 overflow-y-auto">
      <div className="text-center mb-6 md:mb-8">
      <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-3 h-120 md:mb-6 leading-tight">
          Privacy Policy
        </h2>
          <div className="bg-orange-100 rounded-lg p-4 sm:p-6 mb-6 text-left h-100 max-h-[480px] overflow-y-auto">
              <p>
              This Privacy Notice for CreatorOS Inc ("we," "us," or "our"), describes how and why we might access, collect, store, use, and/or share ("process") your personal information when you use our services ("Services"), including when you:
              </p>
              <ul className="list-disc pl-5">
                <li>Visit our website at http://www.reclaimprotocol.org, or any website of ours that links to this Privacy Notice</li>
                <li>Download and use our mobile application (Reclaim Verifier or appclip or instapp), or any other application of ours that links to this Privacy Notice</li>
                <li>Use Reclaim Protocol. A SDK and applications that use Reclaim Protocol SDK that enable users to generate a cryptographical attestation of their user data on the websites. </li>
                <li>Engage with us in other related ways, including any sales, marketing, or events</li>
              </ul>
              <p>
                <b>Questions or concerns? </b> Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:admin@reclaimprotocol.org" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  admin@reclaimprotocol.org
                </a>
                  ,  
                <a href="mailto:contact@reclaimprotocol.org" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  contact@reclaimprotocol.org
                </a>.
              </p>

              <br />
              <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">SUMMARY OF KEY POINTS</h2>
              <div>
                <b>This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our <a href="#toc" style={{ textDecoration: 'underline' }}>table of contents</a> below to find the section you are looking for.</b>
                <p><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about <a href="#1" style={{ textDecoration: 'underline' }}>personal information you disclose to us</a>.</p>
                <p><strong>Do we process any sensitive personal information?</strong> Some of the information may be considered "special" or "sensitive" in certain jurisdictions, for example, your racial or ethnic origins, sexual orientation, and religious beliefs. We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Learn more about <a href="#sensitive" style={{ textDecoration: 'underline' }}>sensitive information we process</a>.</p>
                <p><strong>Do we collect any information from third parties?</strong> We do not collect any information from third parties.</p>
                <p><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <a href="#2" style={{ textDecoration: 'underline' }}>how we process your information</a>.</p>
                <p><strong>In what situations and with which types of parties do we share personal information?</strong> We may share information in specific situations and with specific categories of third parties. Learn more about <a href="#4" style={{ textDecoration: 'underline' }}>when and with whom we share your personal information</a>.</p>
                <p><strong>How do we keep your information safe?</strong> We have adequate organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about <a href="#10" style={{ textDecoration: 'underline' }}>how we keep your information safe</a>.</p>
                <p><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <a href="#10" style={{ textDecoration: 'underline' }}>your privacy rights</a>.</p>
                <p><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by visiting <a href="http://www.reclaimprotocol.org/data-request" style={{ textDecoration: 'underline' }}>http://www.reclaimprotocol.org/data-request</a>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                <p>Want to learn more about what we do with any information we collect? Review the <a href="#1" style={{ textDecoration: 'underline' }}>Privacy Notice in full</a>.</p>
              </div>

              <br />
              <div>
                <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="toc">Table of Contents</h2>
                <ul>
                  <li><a href="#1">1. WHAT INFORMATION DO WE COLLECT?</a></li>
                  <li><a href="#2">2. HOW DO WE PROCESS YOUR INFORMATION?</a></li>
                  <li><a href="#3">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</a></li>
                  <li><a href="#4">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
                  <li><a href="#5">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a></li>
                  <li><a href="#6">6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
                  <li><a href="#7">7. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</a></li>
                  <li><a href="#8">8. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
                  <li><a href="#9">9. HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                  <li><a href="#10">10. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
                  <li><a href="#11">11. DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                  <li><a href="#12">12. WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                  <li><a href="#13">13. CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                  <li><a href="#14">14. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
                  <li><a href="#15">15. DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                  <li><a href="#16">16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                  <li><a href="#17">17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
                </ul>
              </div>


            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="1">1. WHAT INFORMATION DO WE COLLECT? </h2>
            <div>
            <b>Personal information you disclose to us</b>

<p><strong>In Short:</strong> We collect personal information that you provide to us.</p>

<p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

<p><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>

<ul className="list-disc pl-5">
  <li>names</li>
  <li>phone numbers</li>
  <li>email addresses</li>
  <li>user personal information on various websites</li>
  <li>mailing addresses</li>
  <li>job titles</li>
</ul>

<p><strong id="sensitive">Sensitive Information.</strong> When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</p>

<ul className="list-disc pl-5">
  <li>health data</li>
  <li>financial data</li>
  <li>credit worthiness data</li>
  <li>student data</li>
  <li>social security numbers or other government identifiers</li>
  <li>information revealing trade union membership</li>
  <li>any personal information that user wants to generate attestation on</li>
</ul>

<p><strong>Social Media Login Data.</strong> We may provide you with the option to register with us using your existing social media account details, like your Facebook, X, or other social media account. If you choose to register in this way, we will collect certain profile information about you from the social media provider, as described in the section called <a href="#8" style={{ textDecoration: 'underline' }}>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a> below.</p>

<p><strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>

<ul className="list-disc pl-5">
  <li><em>Push Notifications.</em> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</li>
</ul>

<p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>

<p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

<p><strong>Information automatically collected</strong></p>

<p><strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>

<p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>

<p>The information we collect includes:</p>

<ul className="list-disc pl-5">
  <li><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</li>
  <li><em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
  <li><em>Location Data.</em> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
</ul>

<p><strong>Google API</strong></p>

<p>Our use of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy" style={{ textDecoration: 'underline' }}>Google API Services User Data Policy</a>, including the <a href="https://developers.google.com/terms/api-services-user-data-policy#limited-use" style={{ textDecoration: 'underline' }}>Limited Use requirements</a>.</p>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="2">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
            <div>
                <strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and comply with the law. We may also process your information for other purposes with your consent.
                <br />

              <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
              
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>
                  <strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong> We may process your information so you can create and log in to your account, as well as keep your account in working order.
                </li>
                <li>
                  <strong>To deliver and facilitate the delivery of services to the user.</strong> We may process your information to provide you with the requested service.
                </li>
                <li>
                  <strong>To enable user-to-user communications.</strong> We may process your information if you choose to use any of our offerings that allow for communication with another user.
                </li>
                <li>
                  <strong>To request feedback.</strong> We may process your information when necessary to request feedback and to contact you about your use of our Services.
                </li>
                <li>
                  <strong>To send you marketing and promotional communications.</strong> We may process the personal information you send to us for our marketing purposes if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see   
                  <a href="#12" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  "WHAT ARE YOUR PRIVACY RIGHTS?"
                  </a> below.
                </li>
                <li>
                  <strong>To deliver targeted advertising to you.</strong> We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.
                </li>
                <li>
                  <strong>To protect our Services.</strong> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
                </li>
                <li>
                  <strong>To identify usage trends.</strong> We may process information about how you use our Services to better understand how they are being used so we can improve them.
                </li>
                <li>
                  <strong>To determine the effectiveness of our marketing and promotional campaigns.</strong> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.
                </li>
                <li>
                  <strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual's vital interest, such as to prevent harm.
                </li>
              </ul>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="3">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
            <div>
              <strong>In Short:</strong> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
              
              <br />
              <h4>
                <strong>If you are located in the EU or UK, this section applies to you.</strong>
              </h4>

              <p>
                The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on to process your personal information. As such, we may rely on the following legal bases to process your personal information:
              </p>

              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>
                  <strong>Consent.</strong> We may process your information if you have permitted us (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about 
                  <a href="#100" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                    withdrawing your consent
                  </a>.
                </li>
                <li>
                  <strong>Performance of a Contract.</strong> We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
                </li>
                <li>
                  <strong>Legitimate Interests.</strong> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                  <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                    <li>Send users information about special offers and discounts on our products and services</li>
                    <li>Develop and display personalized and relevant advertising content for our users</li>
                    <li>Analyze how our Services are used so we can improve them to engage and retain users</li>
                    <li>Support our marketing activities</li>
                    <li>Diagnose problems and/or prevent fraudulent activities</li>
                    <li>Understand how our users use our products and services so we can improve user experience</li>
                  </ul>
                </li>
                <li>
                  <strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
                </li>
                <li>
                  <strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as in situations involving potential threats to the safety of any person.
                </li>
              </ul>

              <h4>
                <strong>If you are located in Canada, this section applies to you.</strong>
              </h4>

              <p>
                We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can 
                <a href="#100" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  withdraw your consent
                </a> at any time.
              </p>

              <p>
                In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
              </p>

              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                <li>For investigations, fraud detection and prevention</li>
                <li>For business transactions provided certain conditions are met</li>
                <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
                <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
                <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
                <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
                <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                <li>If the information is publicly available and is specified by the regulations</li>
              </ul>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="4">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
            <div>
              <strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following categories of third parties.

              < br />
              <p>
                <strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share your data with third-party vendors, service providers, contractors, or agents <strong>("third parties")</strong> who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.
              </p>

              <p>The categories of third parties we may share personal information with are as follows:</p>

              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Affiliate Marketing Programs</li>
                <li>Ad Networks</li>
                <li>Data Analytics Services</li>
                <li>Data Storage Service Providers</li>
                <li>Retargeting Platforms</li>
                <li>Communication & Collaboration Tools</li>
                <li>Performance Monitoring Tools</li>
                <li>Product Engineering & Design Tools</li>
                <li>AI Platforms</li>
                <li>Cloud Computing Services</li>
                <li>Testing Tools</li>
                <li>User Account Registration & Authentication Services</li>
              </ul>

              <p>We also may need to share your personal information in the following situations:</p>

              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>
                  <strong>Business Transfers.</strong> We may share or transfer your information in connection with or during negotiations of any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                </li>
                <li>
                  <strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
                </li>
                <li>
                  <strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services, or promotions.
                </li>
                <li>
                  <strong>Offer Wall.</strong> Our application(s) may display a third-party hosted "offer wall." Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.
                </li>
              </ul>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="5">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h2>
            <div>
              <strong>In Short:</strong> We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise our Services but are not affiliated with our Services.
              < br />
              <p>
                The Services, including our offer wall, may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third-party websites. Any data collected by third parties is not covered by this Privacy Notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.
              </p>
            </div>
            
            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="6">6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
            <div>
                <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.
              < br />

              <p>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
              </p>

              <p>
                We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
              </p>

              <p>
                To the extent these online tracking technologies are deemed to be a "sale"/"sharing" (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section 
                <a href="#14" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"
                </a>.
              </p>

              <p>
                Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
              </p>

              <strong>Google Analytics</strong>

              <p>
                We may share your information with Google Analytics to track and analyze the use of the Services. The Google Analytics Advertising Features that we may use include:
              </p>
              
              <ul>
                <li>Remarketing with Google Analytics</li>
                <li>Google Analytics Demographics and Interests Reporting</li>
                <li>Google Display Network Impressions Reporting</li>
              </ul>

              <p>
                To opt out of being tracked by Google Analytics across the Services, visit  
                <a href="https://tools.google.com/dlpage/gaoptout" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  https://tools.google.com/dlpage/gaoptout
                </a>. 
                You can opt out of Google Analytics Advertising Features through 
                <a href="https://myadcenter.google.com/home?sasb=true&ref=ad-settings" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  Ads Settings
                </a> and Ad Settings for mobile apps. Other opt out means include 
                <a href="http://optout.networkadvertising.org/" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  http://optout.networkadvertising.org/
                </a> and 
                <a href="http://www.networkadvertising.org/mobile-choice" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  http://www.networkadvertising.org/mobile-choice
                </a>. 
                For more information on the privacy practices of Google, please visit the 
                <a href="https://policies.google.com/privacy" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  Google Privacy & Terms
                </a> page.
              </p>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="7">7. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</h2>
            <div>
              <h3>
                <strong>In short:</strong> <p>We offer products, features, or tools powered by Artificial Intelligence, machine learning or similar technologies. </p>
              </h3>

              <p>
                As part of our Services, we offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies (collectively, "AI Products"). These tools are designed to enhance your experience and provide you with innovative solutions. The terms in this Privacy Notice govern your use of the AI Products within our Services.
              </p>

              <strong>Our AI Products</strong>

              <p>Our AI Products are designed for the following functions:</p>
              
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>AI insights</li>
                <li>AI automation</li>
                <li>Blockchain</li>
                <li>Natural language processing</li>
              </ul>

              <strong>How We Process Your Data Using AI</strong>

              <p>
                All personal information processed using our AI Products is handled in line with our Privacy Notice and our agreement with third parties. This ensures high security and safeguards your personal information throughout the process, giving you peace of mind about your data's safety.
              </p>

              <strong>How to Opt Out</strong>

              <p>
                We believe in giving you the power to decide how your data is used. To opt-out, you can:
              </p>

              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Contact us using the contact information provided</li>
              </ul>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="8">8. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
            <div>
              <strong>In Short:</strong>
              <p>
                If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.
              </p>
              <br />
              <p>
                Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
              </p>
              <p>
                We will use the information we receive only for the purposes that are described in this Privacy Notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
              </p>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="9">9. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
            <div>
              <strong>In Short:</strong>
              <p>
                We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.
              </p>
              <br />
              <p>
                We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us to keep your personal information for longer than the period of time in which users have an account with us.
              </p>
              <p>
                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
              </p>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="10">10. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
            <div>
              <strong>In Short:</strong>
              <p>
                We aim to protect your personal information through a system of organizational and technical security measures.
              </p>
              <br />
              <p>
                We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
              </p>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="11">11. DO WE COLLECT INFORMATION FROM MINORS?</h2>
            <div>
              <strong>In Short:</strong>
              <p>
                We do not knowingly collect data from or market to children under 18 years of age.
              </p>
              <br />
              <p>
                We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at 
                <a href="mailto:contact@reclaimprotocol.org" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  contact@reclaimprotocol.org
                </a>.
              </p>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="12">12. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
            <div>
              <strong>In Short:</strong>
              <p>
                Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
              </p>
              <br />
              <p>
                In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section 
                <a href="#16" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                </a> 
                below.
              </p>
              <p>
                We will consider and act upon any request in accordance with applicable data protection laws.
              </p>
              <p>
                If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your 
                <a href="https://ec.europa.eu/newsroom/article29/items/612080" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  Member State data protection authority
                </a> 
                or 
                <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  UK data protection authority
                </a>.
              </p>
              <p>
                If you are located in Switzerland, you may contact the 
                <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  Federal Data Protection and Information Commissioner
                </a>.
              </p>
              <strong id="100">Withdrawing your consent:</strong>
              <p>
                If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 
                <a href="#16" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                </a> 
                below or by updating your preferences.
              </p>
              <p>
                However, please note that this will not affect the lawfulness of the processing before its withdrawal nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
              </p>
              <strong>Opting out of marketing and promotional communications:</strong>
              <p>
                You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section 
                <a href="#16" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                </a> 
                below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
              </p>
              <strong>Account Information</strong>
              <p>
                If you would at any time like to review or change the information in your account or terminate your account, you can:
              </p>
              <ul>
                <li>Contact us using the contact information provided.</li>
              </ul>
              <p>
                Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
              </p>
              <p>
                If you have questions or comments about your privacy rights, you may email us at 
                <a href="mailto:admin@reclaimprotocol.org" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  admin@reclaimprotocol.org
                </a>, or 
                <a href="mailto:contact@reclaimprotocol.org" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                  contact@reclaimprotocol.org
                </a>.
              </p>
            </div>


            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="13">13. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
            <div>
              <p>
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
              </p>
              <p>
              California law requires us to let you know how we respond to web browser DNT signals. Because there currently is no industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.
              </p>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="14">14. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
            <div>
              <strong>In Short:</strong>
              <p>
                If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.
              </p>
              <br />

              <strong>
              Categories of Personal Information We Collect
              </strong>
              <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
            </div>
            <div>
                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead>
                              <tr>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Category</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Examples</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Collected</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>A. Identifiers</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>B. Personal information as defined in the California Customer Records statute</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Name, contact information, education, employment, employment history, and financial information
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>B. Protected classification characteristics under state or federal law</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>D. Commercial information</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Transaction information, purchase history, financial details, and payment information
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>E. Biometric information</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Fingerprints and voiceprints
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>F. Internet or other similar network activity</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>G. Geolocation data</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Device location
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>H. Audio, electronic, sensory, or similar information</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Images and audio, video or call recordings created in connection with our business activities
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>I. Professional or employment-related information</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>J. Education Information</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Student records and directory information
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>K. Inferences drawn from collected personal information</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>NO</td>
                              </tr>
                              <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }}>L. Sensitive personal Information</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                  Account login information, citizenship or immigration status, contents of email or text messages, drivers' licenses, financial information including account access details, health data and passport numbers
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>YES</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>
                            We only collect sensitive personal information, as defined by applicable privacy laws or the purposes allowed by law or with your consent. Sensitive personal information may be used, or disclosed to a service provider or contractor, for additional, specified purposes. You may have the right to limit the use or disclosure of your sensitive personal information. We do not collect or process sensitive personal information for the purpose of inferring characteristics about you.
                          </p>
                          <p>
                            We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
                          </p>

                          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            <li>Receiving help through our customer support channels;</li>
                            <li>Participation in customer surveys or contests;</li>
                            <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                          </ul>

            </div>
            <div>
            <p>
              We will use and retain the collected personal information as needed to provide the Services or for:
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>Category L - As long as the user wishes to share the data with us and has an account with us</li>
            </ul>

            <strong>Sources of Personal Information</strong>
            <p>
              Learn more about the sources of personal information we collect in 
              <a href="#1" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                "WHAT INFORMATION DO WE COLLECT?"
              </a>
            </p>

            <strong>How We Use and Share Personal Information</strong>
            <p>
              Learn about how we use your personal information in the section, 
              <a href="#2" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                "HOW DO WE PROCESS YOUR INFORMATION?"
              </a>
            </p>

            <strong>Will your information be shared with anyone else?</strong>
            <p>
              We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information in the section, 
              <a href="#4" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
              </a>
            </p>

            <p>
              We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
            </p>

            <p>
              We have not sold or shared any personal information to third parties for business or commercial purpose in the preceding twelve (12) months. We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:
            </p>

            <p>
              The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under 
              <a href="#4" style={{ textDecoration: 'underline', paddingLeft: '3px' }}>
                "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
              </a>
            </p>

            <strong>Your Rights</strong>
            <p>
              You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>Right to know whether or not we are processing your personal data</li>
              <li>Right to access your personal data</li>
              <li>Right to correct inaccuracies in your personal data</li>
              <li>Right to request the deletion of your personal data</li>
              <li>Right to obtain a copy of the personal data you previously shared with us</li>
              <li>Right to non-discrimination for exercising your rights</li>
              <li>Right to opt out of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California's privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</li>
            </ul>

            <p>
              Depending upon the state where you live, you may also have the following rights:
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)</li>
              <li>Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon's privacy law)</li>
              <li>Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California's privacy law)</li>
              <li>Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida's privacy law)</li>
            </ul>
            </div> 

            <div>
              <strong>How to Exercise Your Rights</strong>
              <p>
                To exercise these rights, you can contact us by visiting{' '}
                <a href="http://www.reclaimprotocol.org/data-request" style={{ textDecoration: 'underline' }}>
                  http://www.reclaimprotocol.org/data-request
                </a>
                , by emailing us at{' '}
                <a href="mailto:contact@reclaimprotocol.org" style={{ textDecoration: 'underline' }}>
                  contact@reclaimprotocol.org
                </a>
                ,{' '}
                <a href="mailto:support@reclaimprotocol.org" style={{ textDecoration: 'underline' }}>
                  support@reclaimprotocol.org
                </a>
                , or by referring to the contact details at the bottom of this document.
              </p>

              <p>
                Under certain US state data protection laws, you can designate an authorized agent to request on your behalf. We may deny a request from an authorized agent who does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.
              </p>

              <strong>Request Verification</strong>
              <p>
                Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use the personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
              </p>

              <p>
                If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request, and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
              </p>

              <strong>Appeals</strong>
              <p>
                Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at{' '}
                <a href="mailto:admin@reclaimprotocol.org" style={{ textDecoration: 'underline' }}>
                  admin@reclaimprotocol.org
                </a>
                ,{' '}
                <a href="mailto:contact@reclaimprotocol.org" style={{ textDecoration: 'underline' }}>
                  contact@reclaimprotocol.org
                </a>.
                We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.
              </p>

              <strong>Financial Incentives</strong>
              <p>"Financial incentive" means a program, benefit, or other offering, including payments to consumers as compensation, for the disclosure, deletion, sale, or sharing of personal information. The law permits financial incentives or a price or service difference if it is reasonably related to the value of the consumer's data. A business must be able to explain how the financial incentive or price or service difference is reasonably related to the value of the consumer's data. The explanation must include:</p>
              
              <ul className="list-disc pl-5">
                <li>a good-faith estimate of the value of the consumer's data that forms the basis for offering the financial incentive or price or service difference; and </li>
                <li>a description of the method the business used to calculate the value of the consumer's data.</li>
              </ul>

              <p>We may decide to offer a "bona fide loyalty program" under Colorado law, or a "financial incentive" under California law (e.g., price or service difference) in exchange for the retention, sale, or sharing of a consumer's personal information.</p>
              <p>If we decide to offer a financial incentive, we will notify you of such financial incentive and explain the price difference, as well as material terms of the financial incentive or price of service difference, including the categories of personal information that are implicated by the financial incentive or price or service difference.</p>
              <p>Under California law, the value of your personal information to us is related to the value of the free or discounted products or services, or other benefits that you obtain or that are provided as part of the applicable program, less the expense related to offering those products, services, and benefits to program participants.</p>
              <p>
                If you choose to participate in the financial incentive you can withdraw from the financial incentive at any time by emailing us at{' '}
                <a href="mailto:contact@reclaimprotocol.org" style={{ textDecoration: 'underline' }}>
                  contact@reclaimprotocol.org
                </a>
                ,{' '}
                <a href="mailto:support@reclaimprotocol.org" style={{ textDecoration: 'underline' }}>
                  support@reclaimprotocol.org
                </a>
                , or by referring to the contact details at the bottom of this document.
              </p>

              <strong>California "Shine The Light" Law</strong>
              <div>
                <p>
                  California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section{" "}
                  <strong>"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</strong>
                </p>
              </div>


            </div>


            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="15">15. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
            <div>
              <strong>In Short: </strong>
              <p>Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

              <p>
                We may update this Privacy Notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.
              </p>
            </div>

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="16">16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
            <div>
              <p>
                If you have questions or comments about this notice, you may email us at <a href="mailto:contact@reclaimprotocol.org" style={{ textDecoration: 'underline' }}>contact@reclaimprotocol.org</a> or contact us by post at:
              </p>
              <p>
                CreatorOS Inc<br />
                1043 S Bernardo Ave<br />
                Sunnyvale, CA 94087<br />
                United States
              </p>
            </div>
            

            <br />
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4" id="17">17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
            <div>
              <p>
                You have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please visit <a href="http://www.reclaimprotocol.org/data-request" style={{ textDecoration: 'underline' }}>http://www.reclaimprotocol.org/data-request</a>.
              </p>
            </div>


 
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyScreen;