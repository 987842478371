import React from "react";
import { FaGoogle } from "react-icons/fa";

function CampaignScreen({ onGoogleLogin }) {
  const TitleTextMap = new Map([
    ["https://proofofliving.in", "Proof of Living"],
    ["https://swiggyonegiveaway.in", "Swiggy One Giveaway"],
    ["https://giveawayseason.in", "Giveaway Season"]
  ]);
  const titleText = TitleTextMap.get(window.location.origin) || "Proof of Living";

  return (
    <div
    className="fixed inset-0 flex flex-col items-center justify-center font-roboto-condensed overflow-hidden p-4"
    // onClick={onGoogleLogin} 
    // temporarily remove whole page clickable
  >
      <h1 className="text-white text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-bold mb-4 md:mb-8 text-center">
        {titleText}
      </h1>
      <div className="flex flex-col items-center justify-center text-center bg-white w-full max-w-7xl rounded-lg shadow-lg p-4 sm:p-6 md:p-8 lg:p-10 overflow-hidden">
        <div className="text-center mb-6 md:mb-8">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-orange-600 mb-3 md:mb-6 leading-tight pt-2">
            Win a Swiggy ONE Membership!
          </h2>
          <p className="text-black text-base sm:text-lg mb-4 md:mb-6">
            Prove you have Amazon Prime and Zomato Gold but not Swiggy One
          </p>
          <div className="flex items-center justify-center mb-6">
            <button
              onClick={onGoogleLogin}
              className="flex items-center justify-center bg-white border border-gray-300 rounded-full py-4 px-6 shadow-md hover:shadow-lg transition duration-300 text-sm sm:text-base"
            >
              <FaGoogle className="text-red-500 mr-2" />
              <span className="text-black">Login with Google</span>
            </button>
          </div>
          <div className="bg-orange-100 rounded-lg p-4 sm:p-6 mb-6">
            <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold text-orange-800 mb-3 md:mb-4">How it works:</h3>
            <ol className="text-left text-base sm:text-lg text-orange-900 list-decimal list-inside">
              <li className="mb-2">Login with your Google Account</li>
              <li className="mb-2">We'll verify your memberships securely using zkFetch. (Visit our <a href="https://docs.reclaimprotocol.org/zkfetch" className="underline">website</a> to learn more about zkFetch) </li>
              <li className="mb-2">If eligible, you'll get a chance to win a Swiggy ONE Membership!</li>
            </ol>
          </div>
          <p className="text-gray-600 text-lg sm:text-xl italic mb-4">
            It's that simple! Get started now.
          </p>
          <div className="mt-6 text-gray-600 text-base">
          <p>Click here to view our <a href="/privacy" className="text-orange-600 underline">privacy policy</a></p>
        </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignScreen;