import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CampaignScreen from "./screens/CampaignScreen";
import ProofScreen from "./screens/ProofScreen";
import ResultScreen from "./screens/ResultScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainScreen from "./MainScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/privacy" element={<PrivacyPolicyScreen />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
